<template>
  <van-popup
    v-model:show="show"
    closeable
    round
    safe-area-inset-bottom
    @close="onClose">
    <div class="popup-container">
      <h2 class="popup-title">订单加项确定</h2>
      <van-divider />
      <div class="confirm-base-info">
        <img
          v-if="baseProduct.listImageUrl"
          :src="JSON.parse(baseProduct.listImageUrl)[0] | filterImg"
          alt="" />
        <div class="confirm-product">
          <div class="confirm-product-name">
            {{ baseProduct.productName }}
            <span class="confirm-product-price"
              >￥{{ baseProduct.salePrice }}</span
            >
          </div>
          <div class="confirm-product-tag">
            <span>{{ baseProduct.projectItemCount }}个检查项目</span>
            <span v-if="baseProduct.projectDiseaseItems"
              >筛查{{ baseProduct.projectDiseaseItems.length }}个疾病</span
            >
          </div>
        </div>
      </div>
      <van-divider style="margin: 8px" />
      <div class="add-item-title">
        <span>套餐加项</span>
        <span style="color: #d72c0d">￥{{ addTotalPrice }}</span>
      </div>
      <van-divider style="margin: 8px 8px 0 8px" />

      <div class="confirm-add-item-list">
        <div
          class="confirm-add-item"
          v-for="item in selectedProjects"
          :key="item.projectNo">
          <div class="confirm-add-item-name">
            <div>
              {{ item.projectName }}
            </div>
            <!-- <span>￥{{ item.price }}</span> -->
          </div>
        </div>
      </div>
      <van-divider />

      <div style="padding: 0 16px">
        <van-button color="#00c4b3" round block @click="buy">确定</van-button>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
    baseProduct: {
      type: Object,
      default: () => {},
    },
    selectedProjects: {
      type: Array,
      default: () => [],
    },
    addTotalPrice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    showPopup(val) {
      this.show = val;
    },
  },
  methods: {
    buy() {
      this.$emit("onSubmit");
    },
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style scoped>
.popup-container {
  padding: 0 0 32px 0;
}

.popup-title {
  font-weight: bold;
  color: #000;
  font-size: 16px;
  margin: 16px;
}

.confirm-base-info {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}

.confirm-base-info img {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
}

.confirm-product {
  flex: 1;
  margin-left: 8px;
}
.confirm-product-name {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #202223;
  font-weight: bold;
}

.confirm-product-tag {
  display: flex;
  margin-top: 5px;
}

.confirm-product-tag span {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-right: 4px;
  padding: 0 4px;
  color: #2f87f7;
  height: 20px;
  line-height: 20px;
  background-color: #f3f9fe;
  border-radius: 4px;
}

.confirm-product-price {
  font-size: 14px;
  color: #d72c0d;
}

.add-item-title {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  color: #8c9196;
  font-size: 14px;
}

.confirm-add-item-list {
  padding: 16px 16px 0 16px;
  max-height: 200px;
  overflow-y: scroll;
}

.confirm-add-item {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #f6f6f7;
}

.confirm-add-item:first-child {
  border-top: none;
  padding-top: 0;
  margin-top: 0;
}

.confirm-add-item-name {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #202223;
  line-height: 20px;
}

.confirm-add-item-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  color: #8c9196;
  font-size: 14px;
  line-height: 20px;
}

.confirm-add-item-desc {
  margin-top: 10px;
  color: #6d7175;
  font-size: 14px;
  line-height: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
}
</style>
