<template>
  <div class="right-item" :class="{ 'right-item-active': activePackages }" @click="onClickPackages">
    <div class="right-item-header">
      <div class="package-name">{{ packages.itemName }}</div>
      <div style="display: flex; align-items: center; flex-shrink: 0">
        <div class="right-item-price">¥ {{ packages.price }}</div>

        <img v-if="exclusive" class="checkbox-group" :src="disabled" />
        <img v-else class="checkbox-group" :src="activePackages ? checked : unchecked" alt="" />
      </div>
    </div>

    <div class="right-item-desc">
      {{ packages.desc }}
    </div>
    <div class="right-item-ul" v-if="packages.children?.length">
      <div v-for="(li, projectIndex) in packages.children" :key="li.uniqueKey" class="right-item-li" :style="{
        borderColor: selectedProjectCode.includes(li.uniqueKey)
          ? '#00c4b3'
          : '#edeff0',
      }" @click.stop="onClickProjects(li, packages.isSelectAllSubProject)">
        <div class="right-item-li-left">
          <span class="project-name">
            {{ projectIndex + 1 }}.{{ li.projectName }}
          </span>
        </div>

        <template v-if="!packages.isSelectAllSubProject">
          <div class="right-item-li-right">
            <span>￥{{ li.salePrice }}</span>
            <img v-if="selectedProjectCode.includes(li.uniqueKey)" class="checkbox-group" :src="checked" />
            <img v-else class="checkbox-group" :src="unchecked" />
          </div>
        </template>

      </div>
    </div>
    <div class="disabled-tips" v-if="exclusive">
      选中项与此项目存在相同体检内容，不能同时购买！
    </div>
  </div>
</template>

<script>
import checked from "@/assets/icon-checked.png";
import unchecked from "@/assets/icon-unchecked.png";
import disabled from "@/assets/icon-disabled.png";
export default {
  props: {
    packages: {
      type: Object,
      default: () => { },
    },
    selectedCode: {
      type: Array,
      default: () => [],
    },
    exclusiveCode: {
      type: Array,
      default: () => [],
    },
    selectedProjectCode: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checked,
      unchecked,
      disabled,
    };
  },
  computed: {
    exclusive() {
      return this.$props.exclusiveCode.includes(this.$props.packages.itemCode);
    },
    activePackages() {
      return this.$props.selectedCode.includes(this.$props.packages.itemCode);
    },
  },
  methods: {
    onClickPackages() {
      this.$emit("onClickPackages", this.$props.packages);
    },
    onClickProjects(projects, selectAll) {
      if (selectAll) {
        this.$toast('该项目不支持单选')
      } else {
        this.$emit("onClickProjects", projects);
      }
    },
  },
};
</script>

<style scoped>
.right-item {
  margin: 10px;
  padding: 10px;
  border: 1px solid #edeff0;
  border-radius: 10px;
  background-color: #fff;
}

.right-item-active {
  background-color: #00c4b310;
  border: 1px solid #00c4b3;
}

.right-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  font-weight: bold;
  color: #202223;
}

.right-item-desc {
  margin-top: 8px;
  color: #6d7175;
  font-size: 14px;
  line-height: 20px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.right-item-ul {
  margin-top: 4px;
  width: 100%;
  border-radius: 10px;
}

.right-item-li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  padding: 4px 5px 4px 10px;
  font-size: 13px;
  color: #878d99;
  border: 1px solid #edeff0;
  border-radius: 10px;
  background-color: #fff;
}

.right-item-li-left {
  flex: 1;
}

.right-item-li-right {
  display: flex;
  align-items: center;
}

.icon-meaning {
  vertical-align: middle;
  width: 13px;
  height: 13px;
}

.project-name {
  line-height: 1.5;
}

.package-name {
  color: #202223;
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
}

.right-item-price {
  color: #2c2f37;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
}

.disabled-tips {
  margin-top: 12px;
  color: #ecaa4b;
  font-size: 12px;
}

.base-tips {
  margin: 5px 10px;
  color: #00c4b3;
  font-size: 12px;
}

.checkbox-group {
  margin-left: 5px;
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.checkbox {
  width: 20px;
  height: 20px;
}
</style>
