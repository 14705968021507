<template>
  <div>
    <div class="page-container">
      <!-- 搜索栏 -->
      <SearchBar @onClickSearch="showSearch = true"></SearchBar>

      <div class="main">
        <!-- 分类列表 -->
        <Category
          :badge="badge"
          :categoryList="categoryList"
          :activeIndex="activeIndex"
          :scrollIntoView="scrollIntoView.category"
          @onClickCategory="onClickCategory"></Category>
        <!-- 加项包列表 -->
        <div ref="packagesRef" class="right-container">
          <Packages
            v-for="(item, index) in packagesList"
            :key="item.itemCode"
            :packages="item"
            :selectedCode="selectedCode"
            :exclusiveCode="exclusiveCode"
            :selectedProjectCode="selectedProjectCode"
            @onClickPackages="onClickPackages"
            @onClickProjects="onClickProjects"></Packages>
          <van-empty
            v-if="!packagesList?.length"
            image="search"
            description="暂无加项包"></van-empty>
        </div>
      </div>

      <!-- 底部按钮 -->
      <Bottom
        :totalCount="totalCount"
        :totalPrice="totalPrice"
        @onClickCart="onClickCart"
        @onConfirmCart="onConfirmCart"></Bottom>
    </div>

    <!-- 购物车弹窗 -->
    <Cart
      :show="showCart"
      :baseProduct="baseProduct"
      :addTotalPrice="addTotalPrice"
      :selectedPackages="selectedPackages"
      :selectedProjects="selectedProjects"
      @onClose="showCart = false"></Cart>

    <!-- 确认加项弹窗 -->
    <ConfirmCart
      :baseProduct="baseProduct"
      :showPopup="showConfirm"
      :selectedProjects="selectedProjects"
      :addTotalPrice="addTotalPrice"
      @onSubmit="onSubmit"
      @onClose="showConfirm = false"></ConfirmCart>

    <!-- 搜索页 -->
    <SearchPage
      v-show="showSearch"
      :list="totalPackages"
      @confirm="onSearch"
      @cancel="showSearch = false"></SearchPage>
  </div>
</template>

<script>
import _ from "lodash";
import { Toast } from "vant";

import SearchBar from "./components/search-bar.vue";
import Category from "./components/category.vue";
import Packages from "./components/packages.vue";
import Bottom from "./components/bottom.vue";
import ConfirmCart from "./components/confirm-cart.vue";
import Cart from "./components/cart.vue";
import SearchPage from "./components/search-page.vue";
import {
  GetAddItemTree,
  CalcAddtionPackageAmount,
  GetProductItem,
} from "@/api";
export default {
  components: {
    SearchBar,
    Category,
    Packages,
    Bottom,
    Cart,
    SearchPage,
    ConfirmCart,
  },
  data() {
    return {
      hospitalProductId: this.$route.params.id,
      activeIndex: 0,
      list: [],
      showCart: false,
      baseProduct: {},
      showConfirm: false,
      badge: {},
      showSearch: false,
      scrollIntoView: {},
      selectedPackages: [],
      selectedProjects: [],
      totalPrice: 0,
      addTotalPrice: 0
    };
  },
  computed: {
    //选中的code
    selectedCode() {
      return this.selectedPackages.map((item) => item.itemCode);
    },
    //选中的子项目code
    selectedProjectCode() {
      return this.selectedProjects.map((item) => item.uniqueKey);
    },
    exclusiveCode() {
      return this.selectedProjects
        .map((item) => item.exclusiveItemCodes)
        .flat();
    },
    // 分类列表
    categoryList() {
      return this.list.map((item) => {
        return {
          categoryName: item.groupName,
          iconPath: item.groupImageUrl,
        };
      });
    },
    //加项包列表
    packagesList() {
      return this.list[this.activeIndex]?.addtionalItemList;
    },
    //购物车总数
    totalCount() {
      return this.selectedProjects.length + 1;
    },
    //所有包名
    totalPackages() {
      const temp = [];
      this.list.forEach((item) => {
        item.addtionalItemList.forEach((child) => {
          temp.push(child);
        });
      });
      return temp;
    },
    //所有子项目
    totalProjects() {
      return this.totalPackages.map((item) => item.children).flat();
    },
  },
  watch: {
    selectedProjects: {
      handler(val) {
        this.badge = this.countOccurrences(val.map((item) => item.groupName));
        localStorage.setItem("projects", JSON.stringify(val));
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.init();
    this.getBaseProductInfo();
  },
  methods: {
    //获取套餐基本信息
    async getBaseProductInfo() {
      const res = await GetProductItem({
        itemId: this.$route.params.id,
      });
      this.baseProduct = res;
      this.totalPrice = this.baseProduct.salePrice;
    },

    // 获取加项包数据
    async init() {
      const res = await GetAddItemTree({
        hospitalProductId: this.$route.params.id,
      });
      if (res.length) {
        res.forEach((item) => {
          item.addtionalItemList.forEach((li) => {
            li.groupName = item.groupName;
            li.children.forEach((project) => {
              project.uniqueKey = `${li.itemCode}-${project.projectNo}`;
              project.itemCode = li.itemCode;
              project.groupName = item.groupName;
            });
          });
        });
        this.list = res;
        console.log(this.list);
      }
    },
    //分类点击
    onClickCategory(index) {
      this.activeIndex = index;
      this.$refs.packagesRef.scrollTop = 0;
      this.scrollIntoView = {
        category: "",
        packages: "",
      };
      this.$nextTick(() => {
        this.scrollIntoView = {
          category: "",
          packages: "packages" + 0,
        };
      });
    },
    //加项包点击
    async onClickPackages(packages) {
      const calcProjects = _.cloneDeep(this.selectedProjects);
      const { children, itemCode } = packages;
      if (this.selectedCode.includes(itemCode)) {
        children.forEach((projects) => {
          const index = calcProjects.findIndex(
            (item) => item.uniqueKey === projects.uniqueKey
          );
          if (index > -1) {
            calcProjects.splice(index, 1);
          }
        });
        this.calcPackages(calcProjects).then((res) => {
          //删除包选中
          const index = this.selectedPackages.findIndex(
            (item) => item.itemCode === itemCode
          );
          if (index > -1) {
            this.selectedPackages.splice(index, 1);
          }
          this.selectedProjects = calcProjects;
        });
      } else {
        // 选中包
        children.forEach((projects) => {
          if (!this.selectedProjectCode.includes(projects.uniqueKey)) {
            calcProjects.push(projects);
          }
        });
        this.calcPackages(calcProjects).then((res) => {
          this.selectedProjects = calcProjects;
          this.selectedPackages.push(packages);
        });
      }
    },

    //子项目点击
    async onClickProjects(projects) {
      const calcProjects = _.cloneDeep(this.selectedProjects);
      const { uniqueKey, projectNo, itemCode } = projects;
      if (this.selectedProjectCode.includes(uniqueKey)) {
        //已经选中了，则删除选中
        const index = this.selectedProjects.findIndex(
          (item) => item.uniqueKey === uniqueKey
        );
        if (index > -1) {
          calcProjects.splice(index, 1);
          this.calcPackages(calcProjects).then((res) => {
            this.selectedProjects.splice(index, 1);
            this.onSelectPackages(itemCode);
          });
        }
      } else {
        //未选中添加选中
        calcProjects.push(projects);
        this.calcPackages(calcProjects).then(() => {
          this.selectedProjects.push(projects);
          this.onSelectPackages(itemCode);
        });
      }
    },

    //选中包
    onSelectPackages(itemCode) {
      const packages = this.totalPackages.find(
        (item) => item.itemCode === itemCode
      );
      const siblingsCode = packages.children.map((item) => item.uniqueKey);
      if (
        siblingsCode.every((code) => this.selectedProjectCode.includes(code))
      ) {
        this.selectedPackages.push(packages);
      } else {
        const index = this.selectedPackages.findIndex(
          (item) => item.itemCode === itemCode
        );
        if (index > -1) {
          this.selectedPackages.splice(index, 1);
        }
      }
    },

    calcPackages(calcList) {
      return new Promise(async (resolve, reject) => {
        const itemCodeList = Array.from(
          new Set(calcList.map((item) => item.itemCode))
        );
        const additionProjects = itemCodeList.map((itemCode) => {
          const subChildrenNos = [];
          calcList.forEach((projects) => {
            if (projects.itemCode === itemCode) {
              subChildrenNos.push(projects.projectNo);
            }
          });
          return {
            packageNo: itemCode,
            subChildrenNos,
          };
        });
        Toast.loading({
          duration: 0,
          overlay: true,
          loadingType: "spinner",
        });
        const res = await CalcAddtionPackageAmount({
          hospitalProductId: this.hospitalProductId,
          additionProjects,
        });
        Toast.clear();
        const { addPriceInfo, validateInfo, validateSuccess } = res;
        if (validateSuccess) {
          if (addPriceInfo) {
            this.addTotalPrice = addPriceInfo.addTotalPrice
            this.totalPrice = _.round(
              addPriceInfo.productPrice + addPriceInfo.addTotalPrice,
              2
            );
          }
          resolve(res);
        } else {
          const { type, message, duplicateCodes, exclusiveCodes } =
            validateInfo;
          if (type === "Exclusive") {
            //发生互斥
            const projectNameList = exclusiveCodes.map((code) => {
              return this.totalProjects.find((item) => item.projectNo === code)
                .projectName;
            });
            this.$toast(`${projectNameList.join("与")}互斥`);
          } else if (type === "Duplicate") {
            //发生重复
            const projectNameList = duplicateCodes.map((code) => {
              return this.totalProjects.find((item) => item.projectNo === code)
                .projectName;
            });
            this.$toast(`${projectNameList.join("与")}重复`);
          } else {
            this.$toast(message || "该项目不可选");
          }
          reject();
        }
      });
    },

    countOccurrences(arr) {
      const counts = {};
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        if (counts[element]) {
          counts[element]++;
        } else {
          counts[element] = 1;
        }
      }
      return counts;
    },
    //搜索
    onSearch(packages) {
      this.activeIndex = this.list.findIndex(
        (li) => li.groupName === packages.groupName
      );
    },

    //查看购物车
    onClickCart() {
      this.showCart = !this.showCart;
    },
    // 确认加项
    onConfirmCart() {
      if (this.selectedCode.length) {
        this.showConfirm = true;
      } else {
        this.onSubmit();
      }
    },
    //下单购买
    async onSubmit() {
      await this.calcPackages(this.selectedProjects);
      this.$router.replace("/confirm-order/" + this.hospitalProductId);
    },
  },
};
</script>
<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main {
  flex: 1;
  display: flex;
  width: 100%;
  overflow: hidden;
}

.right-container {
  flex: 1;
  height: 100%;
  background-color: #f4f4f4;
  overflow-y: scroll;
}
</style>
