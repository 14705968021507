<template>
  <div class="footer">
    <div class="footer-left">
      <div class="icon-cart">
        <van-badge :content="totalCount">
          <van-icon
            name="shopping-cart"
            color="#2C2F37"
            size="30px"
            @click="onClickCart"
          />
        </van-badge>
      </div>
      <div class="footer-price">
        合计
        <span class="total-price">¥{{ totalPrice }}</span>
      </div>
    </div>
    <div class="footer-right">
      <div class="footer-button" @click="confirm">选好了</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalCount: {
      type: Number,
    },
    totalPrice: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    onClickCart() {
      this.$emit("onClickCart");
    },
    confirm() {
      this.$emit("onConfirmCart");
    },
  },
};
</script>

<style scoped>
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  width: 100%;
  height: 70px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  border-top: 1px solid #f6f6f7;
  background-color: #fff;
}

.footer-price {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

.footer-left {
  display: flex;
  align-items: center;
}

.icon-cart {
  position: relative;
}

.total-price {
  color: #ff3d00;
  font-size: 20px;
  font-weight: bold;
}

.footer-right {
  display: flex;
}

.footer-button,
.footer-button-default {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 40px;
  border-radius: 20px;
  background: #00c4b3;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}

.footer-button-default {
  margin-right: 10px;
  background-color: #fff;
  color: #878d99;
  border: 1px solid #edeff0;
}
</style>
