<template>
  <div>
    <van-popup
      v-model:show="showCart"
      closeable
      position="bottom"
      round
      safe-area-inset-bottom
      @close="onClose">
      <div class="popup-container">
        <div class="popup-title">已加购</div>
        <div style="max-height: 80vh; overflow-y: scroll">
          <div class="basic-item">
            <span class="cart-item-title">基础套餐</span>
            <div class="cart-product">
              <div>
                {{ baseProduct.productName }}
              </div>
              <div style="color: #ff3d00">￥{{ baseProduct.salePrice }}</div>
            </div>
          </div>

          <div class="cart-item" v-if="selectedProjects?.length">
            <div class="cart-item-title">
              <span>套餐加项</span>
              <span style="color: #ff3d00">￥{{ addTotalPrice }}</span>
            </div>
            <TransitionGroup name="list">
              <van-swipe-cell
                v-for="(item, index) in selectedProjects"
                :key="item.uniqueKey">
                <div class="cart-item-li-content">
                  <div>
                    {{ item.projectName }}
                  </div>
                  <!-- <div style="color: #ff3d00">￥{{ item.salePrice }}</div> -->
                </div>
                <!-- <template #right>
                  <van-button
                    class="delete-button"
                    square
                    type="danger"
                    text="删除"
                    @click="deleteCart(item)" />
                </template> -->
              </van-swipe-cell>
            </TransitionGroup>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    baseProduct: {
      type: Object,
      default: () => {},
    },
    selectedPackages: {
      type: Array,
      default: () => [],
    },
    selectedProjects: {
      type: Array,
      default: () => [],
    },
    addTotalPrice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showCart: false,
    };
  },
  watch: {
    show(val) {
      this.showCart = val;
    },
  },
  methods: {
    deleteCart(item) {
      this.$emit("deleteCart", item);
    },
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style scoped>
.popup-container {
  padding: 16px 16px 0 16px;
}
.popup-title {
  font-weight: bold;
  color: #000;
  font-size: 16px;
}
.cart-item {
  padding-top: 10px;
  border-top: 1px solid #f6f6f7;
  overflow: hidden;
}

.cart-item-title {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 24px;
  color: #8c9196;
}
.cart-item-list {
  padding: 10px 0 0 0;
}
.cart-item-li {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: 1px solid #f6f6f7;

  font-size: 16px;
  line-height: 10px;
  color: #000;
}

.cart-item-li-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  font-size: 14px;
}

.basic-item {
  margin-top: 8px;
  padding: 8px 0 0 0;
  border-top: 1px solid #f6f6f7;
}

.cart-product {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  margin-top: 8px;
  font-size: 16px;
  color: #000;
  border-top: 1px solid #f6f6f7;
}

.delete-button {
  height: 100%;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(15px);
}
</style>
