<template>
  <div class="page-container">
    <div class="search-container">
      <input
        class="input"
        v-model="searchTerm"
        type="text"
        placeholder="请搜索..." />
      <span class="cancel-button" @click="cancelSearch">取消</span>
    </div>
    <div class="search-result" v-show="searchTerm && searchList.length">
      <div
        class="result-item"
        v-for="item in searchList"
        :key="item.itemCode"
        @click="onSearchClick(item)">
        <van-icon name="search" size="16" />
        <span
          class="search-name van-ellipsis"
          v-html="highlight(item.itemName)"></span>
        <van-icon name="arrow" color="#00c4b3"></van-icon>
      </div>
    </div>

    <div class="recommend-container" v-show="!searchTerm || !searchList.length">
      <div class="recommend-header">加项推荐</div>
      <div class="recommend-wrapper">
        <span
          class="recoomend-item"
          v-for="item in recommendList"
          :key="item.additionPackageId"
          @click="onSearchClick(item)"
          >{{ item.itemName }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchTerm: "",
    };
  },
  computed: {
    searchList() {
      if (!this.searchTerm) return;
      return this.$props.list.filter((item) => {
        return item.itemName
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase());
      });
    },
    recommendList() {
      return this.$props.list.slice(0, 6);
    },
  },
  methods: {
    highlight(text) {
      if (!this.searchTerm) {
        return text;
      }
      const regex = new RegExp(`(${this.searchTerm})`, "gi");
      return text.replace(regex, "<span style=color:#00c4b3>$1</span>");
    },
    onSearchClick(item) {
      this.$emit("confirm", item);
      this.cancelSearch();
    },
    cancelSearch() {
      this.searchTerm = "";
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
.page-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f4f4f4;
  z-index: 99;
}

.search-container {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  height: 55px;
  background-color: #fff;
}

.input {
  flex: 1;
  padding: 0 10px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #e1e3e5;
  font-size: 16px;
  color: #2c2f37;
}
.cancel-button {
  display: flex;
  align-items: center;
  padding: 0 15px 0 5px;
  height: 100%;
  font-size: 14px;
  color: #878d99;
}

.search-result {
  margin-top: 10px;
  padding: 0 15px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #fff;
  max-height: calc(100vh - 65px);
  overflow-y: scroll;
}

.result-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  font-size: 15px;
  color: #2c2f37;
}

.result-item:not(:first-child)::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #edeff0;
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleY(0.5);
}

.search-name {
  flex: 1;
  margin: 0 10px;
}

.recommend-container {
  padding: 15px;
  margin-top: 10px;
  background-color: #fff;
  height: 100%;
}

.recommend-header {
  font-size: 15px;
  font-weight: bold;
  color: #2c2f37;
}

.recommend-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.recoomend-item {
  margin: 10px 10px 0 0;
  padding: 6px 12px;
  background: #f4f4f4;
  border-radius: 10px;
  font-size: 15px;
  color: #2c2f37;
}
</style>
