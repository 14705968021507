<template>
  <div class="container-wrapper">
    <div
      class="item-container"
      :id="`category${index}`"
      v-for="(item, index) in categoryList"
      :key="item.categoryName"
    >
      <div
        class="item"
        :style="{
          background: activeIndex === index ? '#00c4b310' : '#fff',
        }"
        @click="onClickCategory(index)"
      >
        <van-badge class="badge" :content="badge[item.categoryName]">
          <img class="item-img" :src="item.iconPath" />
          <div class="category-name">{{ item.categoryName }}</div>
        </van-badge>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categoryList: {
      type: Array,
      default: () => [],
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
    scrollIntoView: {
      type: String,
      default: "",
    },
    badge: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    onClickCategory(index) {
      this.$emit("onClickCategory", index);
    },
  },
};
</script>

<style scoped>
.container-wrapper {
  width: 68px;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
}

.item-container {
  padding-top: 8px;
}

.item-container:not(:first-child) {
  padding-top: 16px;
}

.item {
  position: relative;

  padding: 4px 0;
  font-size: 14px;
  line-height: 20px;
  color: #6d7175;
  margin: 0 8px 0 4px;
  border-radius: 8px;
}

.badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-img {
  margin-bottom: 4px;
  width: 30px;
  height: 30px;
}

.category-name {
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  width: 100%;
  color: #333;
  font-weight: bold;
}
</style>
