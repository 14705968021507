<template>
  <div class="header-container">
    <img class="header-img" src="@/assets/add-item.png" />
    <div class="search-input" @click="onClickSearch">请输入检查项目</div>
  </div>
</template>

<script>
export default {
  methods: {
    onClickSearch() {
      this.$emit("onClickSearch");
    },
  },
};
</script>

<style scoped>
.header-container {
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 12px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.header-img {
  margin-right: 6px;
  width: 54px;
  height: 25px;
}

.search-input {
  display: flex;
  align-items: center;
  padding: 0 20px;
  flex: 1;
  font-size: 14px;
  height: 35px;
  border-radius: 20px;
  border: 1px solid #edeff0;
  color: #cccccc;
}
</style>
